<template>
    <div class="content-wrapper">
      <p class="no-portfolio-title">{{ t('generic.no_selected_portfolio') }}</p>
      <a-row gutter="24" class="mt-4">
        <a-col :key="link.label" v-for="link in linkButtons">
          <sdButton
            class="link-button"
            @click="goToPath(link.path)"
          >
            {{ $t(`sidebar.${link.label}`) }}
          </sdButton>
        </a-col>
      </a-row>
    </div>
  </template>
  <script setup>
  import { useRouter } from "vue-router";
  import { useI18n } from "vue-i18n";
  import { useAuthStore } from "../../stores/AuthStore";
  const { t } = useI18n();
  const router = useRouter();
  const authStore = useAuthStore();
  const linkButtons = [
    {
      label: 'portfolio_generate',
      path: authStore.role_id >= 2 ? 'generate-portfolio-advanced' : 'generate-portfolio',
    },
    {
      label: 'my_portfolio',
      path: 'load-portfolio',
    },
    {
      label: 'portfolio_import',
      path: 'import-portfolio',
    }
  ];
  const goToPath = (path) => {
    router.push({ name: path });
  };
  </script>
  <style scoped lang="scss">
  @import "~@/static/css/colors.scss";
  .content-wrapper {
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .no-portfolio-title {
      font-size: 22px;
      font-weight: bold;
      color: $text-light-color;
    }
    .no-portfolio-description {
      width: 60% !important;
      font-size: 14px;
    }
    & .link-button {
      color: $text-dark-color;
      background-color: transparent !important;
      border: 1px solid $text-dark-color;
      &:hover {
        color: $text-light-color;
        border-color: $text-light-color;
      }
    }
  }
  </style>