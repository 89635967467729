import { defineStore } from "pinia";
// import axios from "axios"
import client from "@/plugins/axios";
import { useAuthStore } from "@/stores/AuthStore";

export const useFundsDefaultSearchesStore = defineStore(
  "useFundsDefaultSearchesStore",
  {
    state() {
      return {
        funds: [],
        selector: [],
        searches: [],
        results: [],
        isLoading: false,
        error: false,
      };
    },
    actions: {
      async getDefaultSearchs() {
        try {
          return client.get("/search-keywords").then((response) => {
            this.searches = response.data;
            return response.data;
          });
        } catch (err) {
          return (this.error = err);
        }
      },
      async getSearchResults(ids, check = false) {
        try {
          this.isLoading = true;
          const data = { default_searchs: ids, only_my_bank: check };
          const authStore = useAuthStore();
          authStore.logAction(`defined_search:${ids}`);
          return client
            .post("/funds/defaults", data)
            .then((response) => {
              this.results = response.data.data;
              return response.data.data;
            })
            .finally(() => {
              this.isLoading = false;
            });
        } catch (err) {
          return (this.error = err);
        }
      },
    },
    persist: {
      enabled: true,
      strategies: [
        {
          key: "searches",
          storage: localStorage,
        },
      ],
    },
  }
);
