<template>
    <a-modal
      v-model:visible="value"
      :title="$t('generic.titles.bests_modal_title', { total: funds.length })"
      type="primary"
      @ok="filterModalOk"
      @cancel="handleCancel"
      class="founds-modal"
    >
      <template #footer>
        <sdButton
          key="submit"
          type="primary"
          :loading="isLoadingModal"
          @click="filterModalOk"
          >{{ $t("portfolio_funds_modal.add_all") }}</sdButton
        >
        <sdButton
          class="mt-2"
          key="cancel"
          type="primary"
          @click="handleCancel"
        >{{ $t("generic.buttons.cancel") }}</sdButton>
      </template>
    </a-modal>
  </template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { useModelWrapper } from "@/composables/useModelWrapper";
import { useBasketStore } from "@/stores/BasketStore";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  funds: {
    type: Array,
    default: function () {
      return [];
    },
  },
  modelValue: {
    type: Boolean,
    default: function () {
      return false;
    },
  },
  searcher: {
    type: Function,
    required: true,
  },
});
const emit = defineEmits(["update:modelValue"]);

const isLoadingModal = ref(false);

const value = useModelWrapper(props, emit);
const basketStore = useBasketStore();
const { t } = useI18n();


const handleCancel = () => (value.value = false);

const filterModalOk = async () => {
  isLoadingModal.value = true;
  const isinArray = props.funds.map((fund) => fund.isin_tkr_id);
  if (isinArray.length > 0) {
    try {
      const result = await props.searcher(isinArray);
      
      if (result && Array.isArray(result)) {
        await basketStore.addAsset(result);
        message.success(t("funds_printer.added_funds"));
        const element = document.getElementById('basket-table-part');
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    } catch (err) {
      console.log(err);
      message.error(t("generic.titles.error"));
    } finally {
      isLoadingModal.value = false;
      value.value = false;
    }
  }
};
</script>
<style lang="scss">
  .founds-modal {
    & .ant-modal-content {
      padding: 2.5rem 4.5rem;
      & .ant-modal-header {
        border: none;
        padding: 0;
        .ant-modal-title {
          font-weight: 700;
        }
      }
      & .ant-modal-footer {
        border: none;
        padding: 0;
        padding-top: 1rem;
        button {
          width: 100%;
          margin-left: 0px !important;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .bests-modal {
      width: 75%;
      & .ant-modal-content {
        padding: 2rem;
      }
    }  
  }
  </style>