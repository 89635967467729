<template>
  <div class="search-card">
    <div class="search-tabs">
      <div
        class="search-tab-item"
        v-for="item in searchTabs"
        :key="item.id"
        @click="changeActiveTab(item)"
        :class="{ 'active': activeSearch === item.id }"
      >
      <p>{{ $t(item.label) }}</p>
      </div>
    </div> 
  </div>
  <div style="padding: 24px 3rem;">
    <template v-if="activeSearch === 'saved_search'">
      <a-row>
        <a-col :xs="18">
          <a-select
            v-model:value="selectedSearchId"
            style="width: 100%; height: 45px;"
            :placeholder="$t('search_funds.saved_search')"
            class="native-select"
            @select="selectSavedSearch"
            :loading="fundsStore.isLoadingSearches"
          >
            <a-select-option
              v-for="(i, index) in fundsStore.saved_searches"
              :key="`saved-search-${index}`"
              :value="i.id"
              :title="i.search"
            >
              {{ i.search }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :xs="6">
          <a-row>
            <div class="px-2 switcher-wrapper">
              <sdButton
                class="switch-btn execute-search"
                :class="!editSearch && 'icon-white'"
                :type="!editSearch ? 'primary' : 'secondary'"
                @click="editSearch = false"
              >
                {{ $t('search_funds.execute_search') }}
              </sdButton>
              <sdButton
                class="switch-btn edit-search"
                :class="editSearch && 'icon-white'"
                :type="editSearch ? 'primary' : 'secondary'"
                @click="editSearch = true"
              >
                {{ $t('search_funds.edit_search') }}
              </sdButton>
            </div>
          </a-row>
        </a-col>
      </a-row>
    </template>
    <template v-else>
      <div class="filters-mobile-row">
        <a-space>
          <sdButton
            size="small"
            type="primary"
            :outlined="!categoricos"
            @click="categoricos = true"
            class="filter-button"
            :class="{ 'selected' : !categoricos }"
          >
            {{ $t("search_funds.filter_criteria.categorical_filters") }}
            <wiser-icon icon="active-dot" v-if="hasCategorical"></wiser-icon>
          </sdButton>
          <sdButton
            size="small"
            type="primary"
            :outlined="categoricos"
            @click="categoricos = false"
            class="filter-button"
            :class="{ 'selected' : categoricos }"
          >
            {{ $t("search_funds.filter_criteria.percentage_filters") }}
            <wiser-icon icon="active-dot" v-if="hasPercentages"></wiser-icon>
          </sdButton>
        </a-space>
    
        <sdButton
          class="float-right restart"
          size="small"
          type="danger"
          :outlined="true"
          @click="reset"
        >
          <Icon class="icon-single">
            <sdFeatherIcons type="rotate-ccw" size="22" />
            <span>{{ $t("search_funds.filter_criteria.reset_filters") }}</span>
          </Icon>
        </sdButton>
      </div>
      <a-row v-if="categoricos">
        <!-- Filtros categóricos -->
        <filter-criteria-category></filter-criteria-category>
      </a-row>
      <a-row v-else>
        <!-- Filtros porcentuales -->
        <filter-criteria-percent></filter-criteria-percent>
      </a-row>
      <a-divider />
      <!-- Divisas, cubierta, morning star -->
      <funds-search-criteria></funds-search-criteria>
      <a-row>
        <div class="search-button">
          <sdButton
            size="small"
            @click="populate(true)"
            :loading="isLoading"
            type="secondary"
          >
            {{ $t("generic.buttons.search") }}
          </sdButton>
        </div>
      </a-row>
    </template>
  </div>
  <a-modal
    v-model:visible="showRename"
    :footer="null"
  >
    <a-row>
      <h6>{{ $t("search_funds.rename_search") }}</h6>
      <div class="search-input-wrapper">
        <a-input
          v-model:value="searchName"
          size="small"
          style="width: 100%; border-color: #02B388 !important;"
        />
        <sdButton
          type="primary"
          style="height: 100%;"
          @click="saveSearch"
          :loading="isLoadingRename"
          >{{ $t("generic.buttons.ok") }}
        </sdButton>
      </div>
    </a-row>
  </a-modal>
  <a-modal
    v-model:visible="visibleEditModal"
    class="custom-search-modal"
    :footer="null"
    v-if="!!fundsStore.selected_search"
  >
    <div style="padding: 1rem 2rem;">
      <div class="selcted-search-title position-relative">
        <p style="margin: 0;">{{ fundsStore.selected_search.search }}</p>
        <wiser-icon
          icon="pencil"
          style="cursor: pointer"
          @click="showRename = true"
        ></wiser-icon>
      </div>
      <div style="margin: 2rem 0;">
        <a-space>
          <sdButton
            size="small"
            type="primary"
            :outlined="!categoricos"
            @click="categoricos = true"
            class="filter-button"
            :class="{ 'selected' : !categoricos }"
          >
            {{ $t("search_funds.filter_criteria.categorical_filters") }}
            <wiser-icon icon="active-dot" v-if="hasCategorical"></wiser-icon>
          </sdButton>
          <sdButton
            size="small"
            type="primary"
            :outlined="categoricos"
            @click="categoricos = false"
            class="filter-button"
            :class="{ 'selected' : categoricos }"
          >
            {{ $t("search_funds.filter_criteria.percentage_filters") }}
            <wiser-icon icon="active-dot" v-if="hasPercentages"></wiser-icon>
          </sdButton>
        </a-space>
      </div>
      <a-row v-if="categoricos">
        <!-- Filtros categóricos -->
        <filter-criteria-category></filter-criteria-category>
      </a-row>
      <a-row v-else>
        <!-- Filtros porcentuales -->
        <filter-criteria-percent></filter-criteria-percent>
      </a-row>
      <a-divider />
      <!-- Divisas, cubierta, morning star -->
      <funds-search-criteria></funds-search-criteria>
      <a-row class="justify-content-between mt-4">
        <a-col>
          <sdButton
            size="small"
            type="primary"
            @click="removeSearch"
            :loading="isLoadingDelete"
          >
            {{ $t("generic.buttons.remove_search") }}
          </sdButton>
        </a-col>
        <a-col>
          <sdButton
            size="small"
            @click="populate(true)"
            :loading="isLoading"
            type="secondary"
            style="padding-left: 32px; padding-right: 32px;"
          >
            {{ $t("generic.buttons.search") }}
          </sdButton>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { message } from "ant-design-vue";
import WiserIcon from "@/components/icons/wiser-icons";
import FilterCriteriaCategory from "@/components/wiseral/filters/FilterCriteriaCategory.vue";
import FundsSearchCriteria from "@/components/wiseral/filters/FundsSearchCriteria.vue";
import FilterCriteriaPercent from "@/components/wiseral/filters/FilterCriteriaPercent.vue";
import { useFundsStore } from "@/stores/FundsStore";

const { t } = useI18n();

const fundsStore = useFundsStore();

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  withNew: {
    type: Boolean,
    default: false
  }
});

const searchTabs = [
  {
    id: 'saved_search',
    label: 'search_funds.saved_search'
  },
  {
    id: 'new_search',
    label: 'search_funds.new_search'
  },
];
const activeSearch = ref('saved_search');
const editSearch = ref(false);
const isLoadingRename = ref(false);
const showRename = ref(false);
const searchName = ref('');
const isLoadingDelete = ref(false);
const visibleEditModal = ref(false);
const selectedSearchId = ref(null);
const categoricos = ref(true);
const hasCategorical = computed(() => !!fundsStore.data.fundsTypes);
const hasPercentages = computed(() =>
  fundsStore.percentages.some((item) => item.percentageFilter)
);

const emit = defineEmits(["populate"]);

const populate = () => {
  emit("populate");
};

const reset = () => {
  fundsStore.resetData();
};

const saveSearch = () => {
  if (fundsStore.saved_searches.find(e => e.search === searchName.value)) {
    message.warning(t('search_funds.save_search_error'));
  } else {
    isLoadingRename.value = true;
    fundsStore.saveSearch(searchName.value).then(() => {
      isLoadingRename.value = false;
      searchName.value = '';
      showRename.value = false;
    }).catch(err => {
      isLoadingRename.value = false;
      console.log("Error: ", err);
      showRename.value = false;
    });
  }
};
const removeSearch = async () => {
  isLoadingDelete.value = true;
  await fundsStore.removeSearch(selectedSearchId.value).then(() => {
    isLoadingDelete.value = false;
    visibleEditModal.value = false;
    selectedSearchId.value = null;
  }).catch(err => {
    console.log("err: ", err);
    isLoadingDelete.value = false;
  });
};

const changeActiveTab = (item) => {
  activeSearch.value = item.id;
  fundsStore.searchId = null;
  if (item.id === 'new_search') {
    fundsStore.resetData();
  }
};

const selectSavedSearch = async (e) => {
  const selectedSearch = fundsStore.saved_searches?.find(item => item?.id === e);
  searchName.value = selectedSearch.search;
  fundsStore.selected_search = selectedSearch;
  fundsStore.searchId = selectedSearchId.value;
  if (editSearch.value) {
    fundsStore.resetData(JSON.parse(selectedSearch.search_sql));
    visibleEditModal.value = true;
  } else {
    fundsStore.resetData(JSON.parse(selectedSearch.search_sql));
    emit("populate", JSON.parse(selectedSearch.search_sql));
  }
};



onMounted(async () => {
  activeSearch.value = props.withNew ? 'new_search' : 'saved_search';
  await fundsStore.fetchMySearches();
});
</script>

<style scoped lang="scss">
@import "~@/static/css/colors.scss";

.search-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;
  & button {
    width: 8rem;
  }
}
.filter-button {
  border-radius: 2.5rem !important;
  &.selected {
    border-color: black !important;
    color: black !important;
  }
}
.ant-divider {
  border-color: rgba(0, 0, 0, 0.8);
}
.search-card {
  padding: 1rem 3rem !important;
}
.search-tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid black;
  margin-bottom: 16px;
  font-size: 14px;
  & .search-tab-item {
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      background-color: #004851;
      color: white;
    }
    &:hover {
      background-color: #004851;
      color: white;
    }
    & p {
      padding-top: 12px;
      padding-bottom: 12px;
      margin: 0 !important;
    }
  }
}
:deep(.native-select) {
  border: 1px #000 solid;
  padding: 0 !important;
  margin: 0 !important;
  .ant-select-selector {
    border: none !important;
    height: 100% !important;
  }
  &.ant-select-open {
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }
  }
}
.switcher-wrapper {
  display: flex;
  width: 100%;
  .switch-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 80%;
    padding: 16px 0 !important;
    &.execute-search {
      border-top-left-radius: 30px  !important;
      border-bottom-left-radius: 30px  !important;
    }
    &.edit-search {
      border-top-right-radius: 30px  !important;
      border-bottom-right-radius: 30px  !important;
    }
  }
}
.selcted-search-title {
  background-color: #004851;
  color: white;
  padding: 12px 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-input-container {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0;
  .rename-input {
    width: 100%;
    border-color: #02B388 !important;
    background-color: white !important;
    color: #004851;
  }
}
@media only screen and (max-width: 1149px) {
  .restart {
    position: absolute;
    margin-top: -70px;
    right: 20px;
  }

  .filters-mobile-row {
    margin-top: 70px;
  }
}
</style>