<template>
    <div class="content-wrapper">
      <p class="empty-basket-title">{{ t('generic.empty_basket_title') }}</p>
      <p class="empty-basket-description">
        {{ t('generic.empty_basket_desc') }}
      </p>
      <a-row gutter="24" class="mt-4">
        <a-col :key="link.label" v-for="link in linkButtons">
          <sdButton
            class="link-button"
            @click="goToSelectedSearch(link.path)"
          >
            {{ $t(`generic.titles.${link.label}`) }}
          </sdButton>
        </a-col>
      </a-row>
    </div>
  </template>
  <script setup>
  import { useRouter } from "vue-router";
  import { useI18n } from "vue-i18n";
  const { t } = useI18n();
  const router = useRouter();
  const linkButtons = [
    {
      label: 'search_funds',
      path: 'search-funds',
    },
    {
      label: 'search_stocks',
      path: 'search-stocks',
    },
    {
      label: 'search_cryptos',
      path: 'search-cryptos',
    },
    {
      label: 'search_benchmark',
      path: 'search-benchmarks',
    },
    {
      label: 'search_etfs',
      path: 'search-etfs'
    },
    {
      label: 'search_pensions',
      path: 'search-pensions'
    }
  ];
  const goToSelectedSearch = (path) => {
    router.push({ name: path });
  };
  </script>
  <style scoped lang="scss">
  @import "~@/static/css/colors.scss";
  .content-wrapper {
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .empty-basket-title {
      font-size: 22px;
      font-weight: bold;
      color: $text-light-color;
    }
    .empty-basket-description {
      width: 60% !important;
      font-size: 14px;
    }
    & .link-button {
      color: $text-dark-color;
      background-color: transparent !important;
      border: 1px solid $text-dark-color;
      &:hover {
        color: $text-light-color;
        border-color: $text-light-color;
      }
    }
  }
  </style>