<template>
  <a-modal
    v-model:visible="value"
    :title="$t('generic.titles.bests_modal_title', { total: funds.length })"
    type="primary"
    @ok="handleOk"
    @cancel="handleCancel"
    class="bests-modal"
  >
    <template #footer>
      <sdButton
        key="submit"
        type="secondary"
        outlined="false"
        :loading="isLoadingModal"
        @click="handleOk"
        >{{ $t("generic.buttons.ok") }}
      </sdButton>
    </template>
    <select class="native-select" :style="{ height: '45px' }" v-model="option">
      <option v-for="(f, fi) in topList" v-bind:key="fi" :value="f">
        <template v-if="f < 1">
          {{ $t("generic.titles.top") }} {{ $n(f, "percent") }}
        </template>
        <template v-else-if="f > 1">
          {{ $t("generic.titles.top") }} {{ f }}
        </template>
        <template v-else>
          {{ $t("search_funds.add_full_list") }}
        </template>
      </option>
    </select>
    <a-checkbox class="mt-4" v-model:checked="cleanBeforeAdd">{{ $t("generic.titles.clean_basket") }}</a-checkbox>
  </a-modal>
</template>
<script setup>
import { computed, ref, defineProps, defineEmits } from "vue";
import { useAuthStore } from "@/stores/AuthStore";
import { useModelWrapper } from "@/composables/useModelWrapper";
import { useBasketStore } from "@/stores/BasketStore";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  funds: {
    type: Array,
    default: function () {
      return [];
    },
  },
  modelValue: {
    type: Boolean,
    default: function () {
      return false;
    },
  },
  searcher: {
    type: Function,
    required: true,
  },
});
const emit = defineEmits(["update:modelValue"]);

const authStore = useAuthStore();
const topList = computed(() => authStore.config.bests_select);
const option = ref(authStore.config?.bests_select[0] || null);
const isLoadingModal = ref(false);
const cleanBeforeAdd = ref(true);

const value = useModelWrapper(props, emit);
const basketStore = useBasketStore();
const { t } = useI18n();

const handleCancel = () => (value.value = false);
const handleOk = async () => {
  let isinArray = props.funds.map((fund) => fund.isin_tkr_id);
  if (option.value < 1) {
    isinArray = isinArray.slice(0, isinArray.length * option.value);
  } else if (option.value > 1) {
    isinArray = isinArray.slice(0, option.value);
  }

  try {
    isLoadingModal.value = true;
    const result = await props.searcher(isinArray);

    if (result && Array.isArray(result)) {
      result.sort(
        (a, b) =>
          isinArray.indexOf(a.isin_tkr_id) - isinArray.indexOf(b.isin_tkr_id)
      );
      if (cleanBeforeAdd.value) {
        await basketStore.deleteAllAssets();
      }
      await basketStore.addAsset(result);
      message.success(t("funds_printer.added_funds"));
      const element = document.getElementById('basket-table-part');
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  } catch (err) {
    console.log(err);
    message.error(t("generic.titles.error"));
  } finally {
    isLoadingModal.value = false;
    value.value = false;
  }
};
</script>
<style lang="scss">
.bests-modal {
  & .ant-modal-content {
    padding: 2.5rem 4.5rem;
    & .ant-modal-header {
      border: none;
      padding: 0;
      .ant-modal-title {
        font-weight: 700;
      }
    }
    & .ant-modal-body {
      padding: 0;
    }
    & .ant-modal-footer {
      border: none;
      padding: 0;
      padding-top: 1rem;
      button {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .bests-modal {
    width: 75%;
    & .ant-modal-content {
      padding: 2rem;
    }
  }  
}
</style>