<template>
  <Main class="general-background">
    <div class="search-card">
      <div class="search-tabs" :style="{ 'grid-template-columns': `repeat(${searchTabs.length}, 1fr)` }">
        <div
          class="search-tab-item"
          v-for="(item, index) in searchTabs"
          :key="item.id"
          @click="changeSearchTab(item.id)"
          :class="{ 'active': activeSearch === item.id, 'borde-r': index !== (searchTabs.length - 1) }"
        >
        <p>
            {{ $t(item.label) }}
            <span
              v-if="
                item.id === SearchCardItems.PORTFOLIO_FUNDS &&
                loadingPortfolioFunds &&
                !authStore.restrictions.includes(Restrictions['SearchPortfolioFunds'])
              "
            ><a-spin /></span>
          </p>
        </div>
      </div>
      <div class="mobile-search-option">
        <a-select
          mode="single"
          v-model:value="activeSearch"
          class="native-select"
          size="small"
          :filterOption="customFilter"
          :defaultActiveFirstOption="true"
        >
          <a-select-option
            v-for="item in searchTabs"
            :key="item.id"
            :value="item.id"
            :title="item.id"
          >
            {{ $t(item.label) }}
          </a-select-option>
        </a-select>
      </div>
      <a-row>
        <a-col :xs="24" :md="16" v-if="!authStore.restrictions.includes(Restrictions['AISelection']) && activeSearch === SearchCardItems.AI_SELECTION">
          <funds-default-searches
            v-model="fundsStore.data.defSeach"
          ></funds-default-searches>
        </a-col>
        <a-col :xs="24" :md="16" v-if="!authStore.restrictions.includes(Restrictions['SearchByName']) && activeSearch === SearchCardItems.BY_FUND_OR_ISIN">
          <asset-by-ticker
            v-model="isinTkr"
            @selectedTkrs="updateTkrs"
            @blur="resetTicker"
            tickerType="funds"
            @selectedSearch="populate"
            :loading="isLoading"
          ></asset-by-ticker>
        </a-col>
        <a-col :xs="24" :md="16" v-if="!authStore.restrictions.includes(Restrictions['SelectTop10']) && activeSearch === SearchCardItems.FUNDS_TOP_TEN">
          <funds-company
            v-model="fundsStore.data.company"
            @selectedFunds="updateCompanyTkrs"
            :assets="fundsStore.fondos"
          ></funds-company>
        </a-col>
        <a-col :xs="24" :md="16" v-if="!authStore.restrictions.includes(Restrictions['ChooseCriteria']) && activeSearch === SearchCardItems.CUSTOM_SEARCH">
          <a-modal
            :visible="activeSearch === SearchCardItems.CUSTOM_SEARCH"
            class="custom-search-modal"
            type="primary"
            :footer="null"
            @cancel="handleCancel"
          >
            <filter-criteria
              :funds="fundsStore"
              @populate="populateFiltering"
              :isLoading="isLoading"
              :withNew="customModalWithNew"
            />
          </a-modal>
        </a-col>
      </a-row>
    </div>
    <basket-card ref="fundsTable" assetType="master_funds_columns">
    </basket-card>
    <founds-modal
      v-model="filterModalVisible"
      :funds="foundResults"
      :searcher="fundsStore.searchFundsByIsinTkr"
      :has-rename="fundsStore.needToSave"
      @change-criteria="onChangeCriteria"
    >
    </founds-modal>
    <portfolio-funds-modal
      v-model="portfolioFundsModalVisible"
      :funds="foundResults"
      :searcher="fundsStore.searchFundsByIsinTkr"
    >
    </portfolio-funds-modal>
  </Main>
</template>

<script setup>
import { Main } from "../styled";
import { ref, provide, watch, onMounted } from "vue";

import FilterCriteria from "@/components/wiseral/filters/filter-criteria";
import AssetByTicker from "../../components/wiseral/searchAnalysis/AssetByTicker.vue";
import FundsCompany from "../../components/wiseral/searchAnalysis/FundsCompany.vue";
import { useAuthStore } from "@/stores/AuthStore";
import { useFundsStore } from "@/stores/FundsStore";
import { useBasketStore } from "@/stores/BasketStore";

import FundsDefaultSearches from "@/components/wiseral/filters/FundsDefaultSearches.vue";
import BasketCard from "@/components/wiseral/basket/BasketCard.vue";
// import { useFundsDefaultSearchesStore } from "@/stores/FundsDefaultSearchesStore";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import FoundsModal from "@/components/wiseral/FoundsModal";
import PortfolioFundsModal from "@/components/wiseral/PortfolioFundsModal";
// import WiserIcon from "@/components/icons/wiser-icons";
import SearchCardItems from "@/config/search/cardIDs";
import Restrictions from "@/config/restriction/restrict_functions";

const router = useRouter();

const { t } = useI18n();

// const fundsDefaultSearchesStore = useFundsDefaultSearchesStore();

// const option = ref(undefined);

// Pinia Stores
const authStore = useAuthStore();
const fundsStore = useFundsStore();
const basketStore = useBasketStore();
const isLoading = ref(false);
// const visible = ref(false);
const filterModalVisible = ref(false);
const portfolioFundsModalVisible = ref(false);
const activeSearch = ref(SearchCardItems.AI_SELECTION);
const loadingPortfolioFunds = ref(false);

const isinTkr = ref([]);
const customModalWithNew = ref(false);

const defaultSearchTabs = [
  {
    id: SearchCardItems.AI_SELECTION,
    label: 'search_funds.select_stored_search'
  },
  {
    id: SearchCardItems.BY_FUND_OR_ISIN,
    label: 'search_funds.search_asset'
  },
  {
    id: SearchCardItems.FUNDS_TOP_TEN,
    label: 'search_funds.funds_by_company'
  },
  {
    id: SearchCardItems.CUSTOM_SEARCH,
    label: 'search_funds.select_filter_criteria'
  },
];

const searchTabs = !authStore.restrictions.includes(Restrictions['SearchPortfolioFunds']) ? [
  ...defaultSearchTabs,
  {
    id: SearchCardItems.PORTFOLIO_FUNDS,
    label: 'search_funds.portfolio_funds'
  },
] : defaultSearchTabs;

provide("funds", useFundsStore());

const changeSearchTab = async (tabKey) => {
  activeSearch.value = tabKey;
  if (tabKey === SearchCardItems.PORTFOLIO_FUNDS) {
    await fetchPortfolioFunds();
  }
};

const fetchPortfolioFunds = async () => {
  loadingPortfolioFunds.value = true;
  try {
    const result = await fundsStore.fetchPortfolioFunds();
    if (result && Array.isArray(result)) {
      foundResults.value = result;
      portfolioFundsModalVisible.value = true;
    }
  } catch (err) {
    console.log(err);
    message.error(t("generic.titles.error"));
  } finally {
    loadingPortfolioFunds.value = false;
  }
};

const handleCancel = () => {
  activeSearch.value = SearchCardItems.AI_SELECTION;
};

const populate = async () => {
  isLoading.value = true;
  try {
    const result = await fundsStore.searchFunds();
    if (result && Array.isArray(result)) {
      await basketStore.addAsset(result);
      message.success(t("funds_printer.added_funds"));
    }
  } catch (err) {
    console.log(err);
  } finally {
    isLoading.value = false;
  }
};

const foundResults = ref();

const onChangeCriteria = () => {
  customModalWithNew.value = true;
  activeSearch.value = SearchCardItems.CUSTOM_SEARCH;
};

const populateFiltering = async (searchData) => {
  isLoading.value = true;
  try {
    const result = await fundsStore.searchFundsFiltering(searchData);

    if (result && Array.isArray(result)) {
      foundResults.value = result;
      filterModalVisible.value = true;
    }
  } catch (err) {
    console.log(err);
    message.error(t("generic.titles.error"));
  } finally {
    isLoading.value = false;
    handleCancel();
  }
};

const updateTkrs = async (e) => {
  authStore.logAction('add_asset');
  e.map((f) => {
    basketStore.addAssetById(e.type, f.id);
  });
};

const updateCompanyTkrs = async (selected) => {
  if (!selected.theSelectedRows.length) {
    return;
  }

  fundsStore.data.isin = selected.theSelectedRows;
  fundsStore.data.company = selected.selectedCompany;

  let allSelectedCompanyFunds = await fundsStore.searchFunds();

  basketStore.addAsset(allSelectedCompanyFunds);

  message.success(t("funds_printer.added_funds"));
};
const resetTicker = () => {
  isinTkr.value = fundsStore.data.isin;
};

watch(
  () => isinTkr.value,
  () => {
    fundsStore.data.isin = isinTkr.value.filter((e) => typeof e === "number");
  }
);

onMounted(async () => {
  fundsStore.resetData();
  const path = router.currentRoute.value.path;
  await basketStore.changeBasketUrl(path);
});
</script>

<style scoped lang="scss">
.general-background {
  background-color: #F2F2F2;
}
.mobile-gap {
  & > * {
    margin: 10px 0;
  }
}
.native-select.modal-select {
  .ant-select-selector {
    border: 1px #000 solid;
    padding: 0px 10px !important;
    margin: 0 !important;
    height: 45px;
  }
  .ant-select-arrow {
    margin-right: 10px;
  }
}
.search-card {
  border: 1px solid #02B388;
  padding: 36px !important;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 7.5rem;
}
.search-tabs {
  display: grid;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-bottom: 16px;
  font-size: 14px;
  & .search-tab-item {
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      background-color: #004851;
      color: white;
    }
    &:hover {
      background-color: #004851;
      color: white;
    }
    &.borde-r {
      border-right: 1px solid black;
    }
    & p {
      padding-top: 12px;
      padding-bottom: 12px;
      margin: 0 !important;
    }
  }
}
.mobile-search-option {
  display: none;
}
@media only screen and (max-width: 768px) {
  .search-tabs {
    display: none;
  }
  .mobile-search-option {
    display: block;
    margin-bottom: 16px;
    & .ant-select {
      padding: 0 !important;
      height: 45px !important;
      .ant-select-selector {
        height: 100% !important;
      }
    }
  }
}
</style>

<style>
.custom-search-modal {
  width: 90% !important;
}
@media only screen and (min-width: 768px) {
  .custom-search-modal {
    width: 70% !important;
  }
}
</style>
