<template>
  <a-col
    :sm="24"
    :xs="24"
    v-for="(p, i) in fundsStore.percentages"
    v-bind:key="i"
  >
    <a-row align="middle" class="my-2">
      <a-col :md="6" :xs="20" class="my-2">
        <a-select
          v-model:value="fundsStore.percentages[i].percentageFilter"
          @change="updateFundsFilters"
          :placeholder="$t('generic.titles.category')"
          class="native-select"
          style="width: 95%; height: 45px"
        >
          <a-select-opt-group
            v-for="(fp, i) in Object.keys(porcentuales)"
            v-bind:key="i"
            :label="$t(`search_funds.percent_filter.${fp}`, fp)"
          >
            <a-select-option
              :value="f"
              v-for="f in Object.keys(porcentuales[fp])"
              v-bind:key="f"
              :title="$t(`search_funds.percent_filter.${f}`, f)"
            >
              {{ $t(`search_funds.percent_filter.${f}`, f) }}</a-select-option
            >
          </a-select-opt-group>
        </a-select>
      </a-col>
      <a-col :md="3" :xs="20" class="my-2">
        <a-select
          v-model:value="fundsStore.percentages[i].operand"
          style="width: 95%; height: 45px"
          :placeholder="$t('generic.titles.operator')"
          class="native-select"
        >
          <a-select-option
            v-for="i in operands"
            :key="i + 'd'"
            :value="i.value"
            :title="$t(`operands.${i.name}`, i.name)"
          >
            {{ $t(`operands.${i.name}`, i.name) }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :md="2" v-if="fundsStore.percentages[i].operand !== 0">
        <percent-input
          v-if="isPercent(fundsStore.percentages[i].percentageFilter)"
          v-model="fundsStore.percentages[i].percentage"
        ></percent-input>
        <a-input-number
          v-else
          v-model:value="fundsStore.percentages[i].percentage"
          :min="0"
          :style="{ height: '45px' }"
        />
      </a-col>
      <a-col :md="2" style="display: flex; justify-content: right;">
        <wiser-icon
          icon="minus"
          @click="fundsStore.removePercentage(i)"
        ></wiser-icon>
      </a-col>
    </a-row>
  </a-col>
  <wiser-icon icon="plus" @click="fundsStore.addPercentage()"></wiser-icon>
</template>

<script setup>
import { computed } from "vue";
import WiserIcon from "@/components/icons/wiser-icons";
import { useFundsStore } from "@/stores/FundsStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useOperandStore } from "@/stores/OperandStore";
import PercentInput from "@/components/wiseral/PercentInput";

const fundsStore = useFundsStore();
const operandStore = useOperandStore();
const authStore = useAuthStore();

const operands = operandStore.operands;
const porcentuales = computed(() => {
  return authStore.config.funds_filtrable_columns.compositions;
});
const isPercent = (field) =>
  authStore.config.master_funds_columns[field]?.format?.format === "percent";
</script>

<style scoped lang="scss">
:deep(.native-select) {
  border: 1px #000 solid;
  padding: 0 !important;
  margin: 0 !important;
  .ant-select-selector {
    border: none !important;
    height: 100% !important;
  }

  &.ant-select-open {
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }
  }
}
</style>
